import template from './editMetadata.html';

export default angular.module('eventix.dashboard.orders.edit.metadata', [])
    .component('editMetaDataModal', {
        controller: EditOrderMetaDataController,
        templateUrl: template,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        }
    }).name;

function EditOrderMetaDataController(UIMessages, $scope, LaravelValidationRules, $timeout) {
    const $ctrl = this;

    $ctrl.$onInit = function() {
        _.assign($ctrl, $ctrl.resolve);
        $scope.$watch('$ctrl.orderMetaData', m => {
            if (!m) return;
            let meta = _.map(m, 'metadata');
            let values = _.map(m, meta => {
                if (meta.metadata.type === 'values' && _.isString(meta.value))
                    return meta.value.split(', ');

                if (meta.metadata.type === 'integer' && meta.value) {
                    return `${meta.value}`;
                }

                return meta.value
            });
            let guids = _.map(meta, 'guid');
            values = _.zipObject(guids, values);
            $ctrl.errors = LaravelValidationRules.validate(meta, values);
        }, true);

        let guids = _.map($ctrl.orderMetaData, 'guid');
        let values = _.map($ctrl.orderMetaData, 'value');
        $ctrl.oldValues = _.zipObject(guids, values);
    };

    $ctrl.edit = function(meta, event) {
        if ($ctrl.editing)
            _.set(_.find($ctrl.orderMetaData, {guid: $ctrl.editing}), 'value', $ctrl.oldValues[$ctrl.editing]);
        $ctrl.editing = meta.guid;

        if (meta.metadata.type === 'values' && _.isString(meta.value))
            meta.value = meta.value.split(', ');

        if (event) {
            let target = angular.element(event.currentTarget);
            let td = target.parents('td');
            let focusTarget = td.find('form').find('input,select,button').get(0);
            if (focusTarget)
                $timeout(() => focusTarget.focus(), 100);
        }
    };

    $ctrl.create = function() {
        let metaData = _.map($ctrl.orderMetaData, 'metadata');
        let values = _.map($ctrl.orderMetaData, meta => {
            if (meta.metadata.type === 'values' && _.isString(meta.value))
                return meta.value.split(', ');

            if (meta.metadata.type === 'integer' && meta.value) {
                return `${meta.value}`;
            }

            return meta.value
        });
        let guids = _.map(metaData, 'guid');
        values = _.zipObject(guids, values);
        if ($ctrl.newMeta.type === 'integer' && $ctrl.newMetaValue) {
            values[$ctrl.newMeta.guid] = `${$ctrl.newMetaValue}`;
        } else {
            values[$ctrl.newMeta.guid] = $ctrl.newMetaValue;
        }
        $ctrl.errors = LaravelValidationRules.validate(metaData, values);
        if (invalid())
            return;
        $ctrl.busy = true;
        let data = {
            metadata_id: $ctrl.newMeta.guid,
            metadata: $ctrl.newMeta,
            value: $ctrl.newMetaValue
        };
        $ctrl.saveFn(data, true)
            .then(meta => {
                meta.metadata = $ctrl.newMeta;
                $ctrl.orderMetaData.push(meta);
                _.remove($ctrl.abstractMetaData, $ctrl.newMeta);
                $ctrl.newMeta = null;
                $ctrl.busy = false;
                $ctrl.editing = null;
            }, e => {
                UIMessages.push(e.description || e.message || 'common.notice.error');
                $ctrl.busy = false;
            })
            .finally(() => {
                if (meta.metadata.type === 'values' && _.isArray(meta.value))
                    meta.value = meta.value.join(', ');
            });
    };

    $ctrl.save = function(meta) {
        if ($ctrl.busy)
            return;

        let values = _.zipObject([meta.metadata.guid], [
            meta.metadata.type === 'integer' && meta.value ? `${meta.value}` : meta.value,
        ]);
        $ctrl.errors = LaravelValidationRules.validate([meta.metadata], values);
        if (invalid())
            return;
        $ctrl.busy = true;
        $ctrl.saveFn(meta, false)
            .then(() => {
                $ctrl.busy = false;
                $ctrl.editing = null;
            }, e => {
                UIMessages.push(e.description || e.message || 'common.notice.error');
                $ctrl.busy = false;
            })
            .finally(() => {
                if (meta.metadata.type === 'values' && _.isArray(meta.value))
                    meta.value = meta.value.join(', ');
            });
    };

    function invalid() {
        let nonEmptyErrors = _.findKey($ctrl.errors, e => !_.isEmpty(e));
        return !_.isNil(nonEmptyErrors);
    }
}

